<template>
  <div class="create-category">
    <el-row type="flex" align="middle" class="page-title">
      <i class="el-icon-menu mr-s" />
      <h3>Cập nhật danh mục bài viết: <span>Blockchain</span></h3>
    </el-row>
    <el-row>
      <el-col :span="24" :lg="16">
        <el-card v-loading="loadingData">
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="Tên danh mục" prop="name">
              <el-input
                v-model="form.name"
                :display="callingAPI"
                placeholder="Tên danh mục"
              />
            </el-form-item>
            <el-form-item label="Mô tả">
              <el-input
                type="textarea"
                :rows="3"
                v-model="form.description"
                :display="callingAPI"
                placeholder="Mô tả"
              />
            </el-form-item>
            <el-form-item label="SEO title">
              <el-input
                v-model="form.metaTitle"
                :display="callingAPI"
                placeholder="SEO title"
              />
            </el-form-item>
            <el-form-item label="SEO keyword">
              <el-input
                v-model="form.metaKeyword"
                :display="callingAPI"
                placeholder="SEO keyword"
              />
            </el-form-item>
            <el-form-item label="SEO description">
              <el-input
                v-model="form.metaDescription"
                :display="callingAPI"
                placeholder="SEO description"
              />
            </el-form-item>
          </el-form>
          <el-row type="flex" justify="end" class="mt-xl">
            <el-button
              class="mt-xl"
              type="primary"
              :loading="callingAPI"
              :display="callingAPI || loadingData"
              @click="handleUpdateCategory"
            >
              Cập nhật
            </el-button>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { fetchCategoryById, updateCategory } from "@/services/category";
const slugify = require("slugify");
export default {
  name: "UpdateCategory",
  data() {
    return {
      loadingData: false,
      callingAPI: false,

      form: {
        name: "",
        metaTitle: "",
        metaKeyword: "",
        metaDescription: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Tên danh mục không được để trống",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  async beforeMount() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loadingData = true;
        const response = await fetchCategoryById(this.$route.params.id);
        this.form = {
          ...this.form,
          name: response.data.name,
          description: response.data.description,
          metaTitle: response.data.meta_title,
          metaKeyword: response.data.meta_keyword,
          metaDescription: response.data.meta_description,
        };
        this.loadingData = false;
      } catch (error) {
        this.loadingData = false;
      }
    },

    async handleUpdateCategory() {
      try {
        this.callingAPI = true;
        const data = {
          name: this.form.name,
          description: this.form.description,
          slug: slugify(this.form.name),
          meta_title: this.form.metaTitle,
          meta_keyword: this.form.metaKeyword,
          meta_description: this.form.metaDescription,
        };
        await updateCategory(this.$route.params.id, data);
        this.callingAPI = false;
        this.$router.push({ name: "Categories" });
        this.$notify.success({
          title: "Thành công",
          message: "Cập nhật danh mục thành công.",
          duration: 2000,
        });
      } catch (error) {
        this.callingAPI = false;
        this.$notify.error({
          title: "Thất bại",
          message: "Cập nhật danh mục thất bại.",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
